.footer{
    flex-shrink: 0; 
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: $font-display;
    background-color: FFFFFF;
    color: $teachla-green;

    a {
        color:#313235;
        text-decoration: none;
    }

    .footer-content {
        display: grid;

        @media (min-width: $desktop-width) {
            grid-template-columns: 2fr 4fr;
            column-gap: 2rem;
        }
        box-shadow: 0px -2px 7px 
        rgba(0, 0, 0, 0.1);

        position: absolute;
        padding-top: 50px;
        padding-bottom: 50px;
        left: 0;
        right: 0;
    }

    .footer-aside {
        margin: 0 auto;
        position: relative;
    }

    .logo {
        position: relative;
        top: 100px;
    }

    .footer-links {
        position: relative;
        left: 5px;
        column-gap: 12px;
    }


    .footer-main {
        display: grid;
        padding-left: 15px;
        height: 300px;

        @media (min-width: $desktop-width) {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 1rem;

            #row2first {grid-area: 2 / 1 / 2 / 3; }
            #row2second {grid-area: 2 / 3 / 2 / 3; }
            #row2third {grid-area: 2 / 4 / 2 / 6; }
        }
    }

    #row2second {
        display: flex;
        flex-direction: column;
        max-height: 10 rem;
        flex-wrap: wrap;
    }

    .footer-col {
        padding-bottom: 1rem;

        &.no-title {
            padding-top: 3rem;
        }
    }

    .footer-title{
        margin-top: 0;
        margin-bottom: 0.5rem;

        font-size: 8vw;

        @media (min-width: $desktop-width) {
            font-size: 2.2rem;
            margin-bottom: 2rem;
        }
    }

    .footer-subtitle {
        margin: 0;
        font-size: 14px;
        line-height: 2.5;
        font-weight: normal;

    }

    .footer-links {
        display: flex;
        flex-direction: row;

        padding-left: 0;
        margin: 0;

        list-style-type: none;

        li {
            padding-right: 1rem;
        }

        li:last-child {
            padding-right: 0;
        }
    }

    .footer-link {
        display: flex;
        align-items: center;

        .icon {
            font-size: 1.3rem;
        }

        &.email {
            margin: 0;
            margin-top: 0.5rem;

            .icon {
                margin-right: 0.5rem;
            }
        }
    }

    .footer-copyright {
        margin-top: 2rem;
    }

    .footer-nav {
        margin: 0;
        padding: 0;

        font-size: 14px;
        list-style: none;

        a {
            line-height: 1.5rem;
        }
    }
}
