.profile-img-container{
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    padding-right: 1.2em;
    width: 7em;
    @media (max-width: $desktop-width) {
        width: 40vw;
    }
}

.profile-img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.team-member-content{
    word-wrap: break-word;
    @media (max-width: $desktop-width) {
        > h3 {
            font-size: 1em;
        }

        > .team-member-position {
            font-size: .75em;
        }
    }
    @media (min-width: $desktop-width) {
        > p {
            margin: 0;
        }
    }
}

.team-profile {
    margin-top: 2em;
}

.team-posts {
    margin-top: 3em;
}

.grid-col{
    @media(max-width: $desktop-width){
        .team-member-content{
            width: 40vw;
        }
        .tla-link{
            overflow-wrap: break-word;
            width: 1vw;
            font-size: .75em;
        }
    }
}

.team-row {
    @media (max-width: $desktop-width) {
        flex-flow: column nowrap;
    }
}
