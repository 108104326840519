:root {
  --primary: #5eda12;
  --neutral: #585858;
  --black: #313235;
}

.top-padding {
  padding-top: 3.5rem;
}

.blogs {
    display: grid; 
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 4rem; 

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 2rem; 
    }
}

.post-card {
  display: flex;
  gap: 2rem;

  width: 100%;
  padding: 3rem;
  padding-right: 6rem;

  border-radius: 15px;
  border: 1px solid var(--primary);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
  }
}

.post-card.small-post-card {
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border: 0px; 
  height: 100%; 
}

.post-card .small-post-card--image {
  max-height: 200px;
  max-width: 100%; 
  border-radius: 15px;
}

.post-card--description {
  flex: 1 1 0px;
  color: var(--black);

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h4 {
    font-size: 12px;
    font-weight: 400;
  }

  span {
    color: var(--primary);

    font-size: 18px;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
  }

  hr {
    border: 1px solid var(--primary);
    margin: 10px 0;
  }

  * {
    margin: 0.3rem;
    padding: 0;
  }
}

.post-card--image {
  flex: 1 1 0px;
  object-fit: cover;

  max-width: 40%;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 200px;

    border-radius: 15px;
  }
}


@media (min-width: $desktop-width) {
    .post-preview{
        display: grid;
        align-items: center;
        grid-template-rows: 1fr;
        grid-template-columns: 5fr 8fr;
    }
}

.post-preview-img{
    margin: 0.5em;
    margin-right: 1em;
    width: 100%;
    height: auto;
    align-self: center;
}

.post-info {
    margin: 1.5em 0;
    padding: 0.5em 10px;
    padding-left: 1em;

    @media (min-width: $desktop-width) {
        // this is the green bar on the left of the info;
        // we hide it on mobile
        border-left: 10px solid $teachla-green;
        margin-left: 1em;
        margin-right: 0.5em;
    }

    a {
        @include tla-link();
    }

}

.post-container {
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
}

.post-main-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    height: auto;
}

.post-author-grid{
    height: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2em;
    > div {
        margin-bottom: 1em;
    }

    @media (max-width: $desktop-width) {
        grid-template-columns: 100%;
        > div {
            margin-bottom: 2em;
        }
    }
}

.post-author-img{
    width: 4em;
    height: 4em;
    display: block;
    border-radius: 10px;
}

.post-author-info{
    padding-left: 1.5em;
}

.post-want-more {
    font-size: 1.5rem;
    margin-top: 1em;
    margin-bottom: 2em;
    @media (min-width: $desktop-width) {
        // pads out l/r on desktop only
        margin: 2em;
    }
}

.post-recommendations{
    @media (max-width: 330px) { // for smaller devices, adjust title font size to fit into card
        font-size: 1rem;
    }
    font-size: 1.25rem;
}

.post-recommend-container{
    @media (min-width: $desktop-width) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    margin-top: 1.5em;
}

.post-recommend-card{
    display: grid;
    grid-template-columns: auto;
    padding: 1em;
    margin: 1em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    border: 2px solid $teachla-green;
    border-radius: 10px;

}

.post-recommend-img{
    align-self: center;
    width: 90%;
    height: auto;
    margin: 1em;
    margin-left: auto;
    margin-right: auto;
}

.post-recommend-info{
    align-self: flex-end;
    margin: 1em;
    font-size: 0.9rem;
}



.post-img {
    padding: 2%;
    max-width: 96%;
}

.social{
  font-family: $font-display;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-buttons{
  display: flex;
  flex-direction: row;
}

[hidden] {    //for older IDEs to display correct label(acessibility) for icon
    display: none;
}

.fa-rss-square {
    color: orange;
}

.social-RSS-icon {
    font-size: 2rem;
}

a {
    text-decoration: none;
}