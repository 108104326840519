.generated-content{
    h1, h2, h3, h4, h5, h6 {
        font-family: $font-header;
        margin-bottom: 0;
    }

    a {
        @include tla-link();
    }

    p {
        line-height: 1.5;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }
}
