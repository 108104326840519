#navbar {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	height: 70px;
	width: 100%;
	background: white;
	box-shadow: 0px 1px 4px #E2E2E2;
	font-family: $font-display;
	color: $font-primary-color;

	#navbar-inner {
		width: 100%;
		margin: auto;
		max-width: 1400px;
	}

	.nav-section.left {
		float: left;
		margin-left: 20px;
	}

	.nav-section.right {
		margin-right: 20px;
		float: right;
	}

	#acm-tla-wordmark {
		height: 50px;
		width: auto;
		margin-top: 10px;
		background-color: white; 
	}

	ul.nav-items {
		list-style: none;
		height: 100%;
		margin: 0;
		padding: 0;
		display: inline-block;
	}

	ul.nav-items li {
		display: inline-block;
		text-transform: lowercase;
		font-size: 0.95em;
		padding: 0px 20px;
		line-height: 40px;
		margin-top: 13px;
		font-weight: 600;
		transition: 0.2s;

		&.nav-button {
			background: white;
			border-radius: 10px;
			margin-left: 15px;
			color: $teachla-green;
			border: 2px solid $teachla-green;
		}

		&.nav-button:hover {
			color: white;
			background: $teachla-green;
		}

		&.nav-button:active {
			background-color: $acm-black;
			border: 2px solid $acm-black;
		}
	}

	ul.nav-items li a:hover {
		color: #999999;
		cursor: pointer;
	}

	a, a:visited {
		color: inherit;
	}

	#mobile-nav {
		display: none;
	}

	.hamburger-icon {
		height: 50px;
		width: 50px;
		margin: 10px 0;
		display: flex;
		justify-content: center;
		flex-direction: column;

		&:hover {
			cursor: pointer;

			.bar {
				margin: 3px 0px;
				background-color: $font-primary-color;
			}
		}

		.bar {
			align-self: center;
			width: 50%;
			height: 3px;
			border-radius: 4px;
			margin: 2px 0px;
			transition: 0.35s cubic-bezier(0.05, 1.04, 0.72, 0.98);
			background-color: $font-secondary-color;
		}
	}

	#menu-toggle {
		display: none;
	}

	#hamburger-menu {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		z-index: -20;
		transform: translate3d(0, -100%, 0);
		background: rgba(255, 255, 255, 0.95);
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: transform 0.35s cubic-bezier(0.05, 1.04, 0.72, 0.98);

		ul.nav-items {
			list-style: none;
			display: block;
			margin: auto;
			margin-top: 100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			float: none;
			padding: 0px;
		}

		ul.nav-items li {
			display: block;
			margin: 30px auto;
			padding: 0px;
			text-transform: lowercase;
			transform: translate3d(0, -50%, 0);
			opacity: 0;
			font-size: 1.8em;
			font-weight: 600;
			transition: all 1.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);

			&.nav-button {
				padding: 10px 20px;
			}

			&.nav-button:hover {
				color: white;
			}
		}

		ul.nav-items li:hover {
			color: #999999;
			cursor: pointer;
		}
	}

	#menu-toggle:checked + #hamburger-menu {
		transform: translate3d(0, 0, 0);

		ul.nav-items li {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}

	a {
		text-decoration: none;
	}

	a.active li,
	a.active ul.nav-items li:hover { 
		color: $teachla-green !important;
	}

	@media (max-width: $desktop-width) {
		#desktop-nav { display: none; }
		#mobile-nav { display: inline-block; }
	}
}
