$acm-cobalt: #3B59ED;
$acm-black: #262938;
$acm-tint: #ACBAFA;

$teachla-green: #5EDA12;
$teachla-black: #2C3022;
$teachla-tint: #5EDA12;

$ai-blue: #5ABBEF;
$py-blue: #1758d7;
$py-blue-tint: #1246ac;
$py-green: #ADDF7F;
$py-green-tint: #9cc972;
$py-gold: #d0bc0a;
$rnative-blue: #61DAFB;

$font-display: 'Poppins', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-header: 'Poppins', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-body: 'Poppins', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-code: 'Source Code Pro', monospace;

$font-primary-color: #333333;
$font-secondary-color: #777777;

$desktop-width: 850px;
$pronouns-font-size: 0.6em;
$code-font-size: 0.8em;

$sidebar-width: 300px;
$sidebar-padding-sides: 15px;

$desktop-width-xl: $desktop-width + 300px;
$ai-desktop-sidebar-content-height: 759px;
$ai-desktop-sidebar-total-width: 220px;
$container-restricted-width: 1000px;
$sidebar-padding-sides: 10px;
