
.sidebar {
    top: 70px;
    left:0;
    width: $sidebar-width; 
    height: 100%;
    display:flex;
    justify-content: flex-end;
    padding: 25px $sidebar-padding-sides 0px $sidebar-padding-sides;
    position: sticky;
    @media (max-width: $desktop-width) {
        display: none;
    }
}

.sidebar-content {
    width: 250px;
}

//New sections for sidebar add CSS here

#dev-setup-button:checked + #dev-setup {
    ol.sidebar-items li {
    display: list-item;
    }   
}

#add-to-team-page-button:checked + #add-to-team-page {
   ol.sidebar-items li {
   display: list-item;
    }
}

input {
    display: none;
}

label {
    color: $font-primary-color;
    cursor: pointer;
    font-size: 1.3em;
}

label:hover {
    color: black;
}

ol.sidebar-items li {
    display: none;
}

ol.sidebar-items li a{
    background-image: none;
    color: $font-primary-color;
    font-weight: normal;
}

#selected {
    font-weight: bold;
}

ol.sidebar-items li a:hover {
    color:black;
}

#docs-pages-content{
    padding: 0 7.5px 0 7.5px;
    width:100%;
    max-width: 1100px; /* This will get rid some of the extra white space on the sidebar*/
}

#docs-pages-content-width{
    width:1000px;
    max-width: calc(100vw - #{$sidebar-width});
    @media (max-width: $desktop-width) {
        max-width: calc(100vw - 3em);
    }
}

#docs-pages {
    left:0;
    display: flex;
    justify-content: center;
}

.generated-content {
    img {
        max-height: 25rem;
        max-width: 94%;
        padding: 2%;
    }

    pre {
        white-space: pre-wrap;
        border-radius: 4px;
        padding: 15px;
        border: 2px solid $teachla-green;
    }

    code {
        font-family: $font-code;
        font-size: $code-font-size;
    }

    // syntax-highlighting
    .highlight {
        padding-left: 1em;
        background: #FCFCFC;

        pre {
            padding: 2%;
            line-height: 1.2;
            white-space: pre-wrap; 
            white-space: -moz-pre-wrap; 
            white-space: -pre-wrap; 
            white-space: -o-pre-wrap; 
            font-family: $font-code;
        }

        .highlighter-rouge & {
            background: #FCFCFC;
        }

        .hll { background-color: #ffffcc }
        .c { color: #999988; font-style: italic } /* Comment */
        .err { color: #a61717; background-color: #e3d2d2 } /* Error */
        .k { color: #000000; font-weight: bold } /* Keyword */
        .o { color: #000000; font-weight: bold } /* Operator */
        .cm { color: #999988; font-style: italic } /* Comment.Multiline */
        .cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
        .c1 { color: #999988; font-style: italic } /* Comment.Single */
        .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
        .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
        .ge { color: #000000; font-style: italic } /* Generic.Emph */
        .gr { color: #aa0000 } /* Generic.Error */
        .gh { color: #999999 } /* Generic.Heading */
        .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
        .go { color: #888888 } /* Generic.Output */
        .gp { color: #555555 } /* Generic.Prompt */
        .gs { font-weight: bold } /* Generic.Strong */
        .gu { color: #aaaaaa } /* Generic.Subheading */
        .gt { color: #aa0000 } /* Generic.Traceback */
        .kc { color: #000000; font-weight: bold } /* Keyword.Constant */
        .kd { color: #000000; font-weight: bold } /* Keyword.Declaration */
        .kn { color: #000000; font-weight: bold } /* Keyword.Namespace */
        .kp { color: #000000; font-weight: bold } /* Keyword.Pseudo */
        .kr { color: #000000; font-weight: bold } /* Keyword.Reserved */
        .kt { color: #445588; font-weight: bold } /* Keyword.Type */
        .m { color: #009999 } /* Literal.Number */
        .s { color: #d01040 } /* Literal.String */
        .na { color: #008080 } /* Name.Attribute */
        .nb { color: #0086B3 } /* Name.Builtin */
        .nc { color: #445588; font-weight: bold } /* Name.Class */
        .no { color: #008080 } /* Name.Constant */
        .nd { color: #3c5d5d; font-weight: bold } /* Name.Decorator */
        .ni { color: #800080 } /* Name.Entity */
        .ne { color: #990000; font-weight: bold } /* Name.Exception */
        .nf { color: #990000; font-weight: bold } /* Name.Function */
        .nl { color: #990000; font-weight: bold } /* Name.Label */
        .nn { color: #555555 } /* Name.Namespace */
        .nt { color: #000080 } /* Name.Tag */
        .nv { color: #008080 } /* Name.Variable */
        .ow { color: #000000; font-weight: bold } /* Operator.Word */
        .w { color: #bbbbbb } /* Text.Whitespace */
        .mf { color: #009999 } /* Literal.Number.Float */
        .mh { color: #009999 } /* Literal.Number.Hex */
        .mi { color: #009999 } /* Literal.Number.Integer */
        .mo { color: #009999 } /* Literal.Number.Oct */
        .sb { color: #d01040 } /* Literal.String.Backtick */
        .sc { color: #d01040 } /* Literal.String.Char */
        .sd { color: #d01040 } /* Literal.String.Doc */
        .s2 { color: #d01040 } /* Literal.String.Double */
        .se { color: #d01040 } /* Literal.String.Escape */
        .sh { color: #d01040 } /* Literal.String.Heredoc */
        .si { color: #d01040 } /* Literal.String.Interpol */
        .sx { color: #d01040 } /* Literal.String.Other */
        .sr { color: #009926 } /* Literal.String.Regex */
        .s1 { color: #d01040 } /* Literal.String.Single */
        .ss { color: #990073 } /* Literal.String.Symbol */
        .bp { color: #999999 } /* Name.Builtin.Pseudo */
        .vc { color: #008080 } /* Name.Variable.Class */
        .vg { color: #008080 } /* Name.Variable.Global */
        .vi { color: #008080 } /* Name.Variable.Instance */
        .il { color: #009999 } /* Literal.Number.Integer.Long */
    }
}
