.button.button-ai {
	@include button-classes($ai-blue);
}

.ai-link {
	@include class-link($ai-blue);
}

.border-ai {
	border-color: $ai-blue !important; // needed to override the border shorthand
}

.divider-ai {
	@include divider-class($ai-blue);
}

.page-container {
    display: grid;
    #desktop-sidebar {
        margin-left: calc(50vw - (0.5 * #{$container-restricted-width} + #{$ai-desktop-sidebar-total-width} + 16px));   //(this is bad, but em and px are incompatible) 16px=1em is padding in container 
    }
    @media (max-width: $container-restricted-width + 2*$ai-desktop-sidebar-total-width + 32px) { //(this is bad, but em and px are incompatible) 2em=32px is left and right padding in container
        #desktop-sidebar{
            margin-left: 10px;
        }
        display: flex;
    }
}

.main-content {
    @media (max-width: $container-restricted-width + 2*$ai-desktop-sidebar-total-width + 32px) { //(this is bad, but em and px are incompatible) 2em=32px is left and right padding in container
        margin-left: 0px;
    }
}

.top-left-grid-position {
    grid-column: 1/2;
    grid-row: 1/2;
}

.ai-lesson-card {
	@include lesson-card($ai-blue);
}

.ai-content-card {
	@include class-content-card($ai-blue);
	@media (max-width: $desktop-width-xl) and (min-width: $desktop-width) {
		grid-column: 2 / span 2;
		grid-row: 1 /2;
	}
}

.ai-slide-iframe{
	display: block;
	width: 100%;
	height: calc(100% * 1469/2559);
	@media (max-width: $desktop-width-xl) and (min-width: $desktop-width) {
		grid-column: 2 / span 2;
		grid-row: 2 / 3;
		height: 100%;
	}
	@media (max-width: $desktop-width) {
			height: 100%;
	}
}

.iframe-container {
	padding: 3em;
	width: calc(100% - 6em);
	position: relative;
}

.current-dot{
	height:10px;
	width:10px;
	background-color: $ai-blue;
	border-radius: 50%;
	display: inline-block;
	box-shadow: 1px 1px 3px grey;
}

#desktop-sidebar{
    top: 80px;
    width: $ai-desktop-sidebar-total-width - 20px;
    margin:48px 10px;
    padding:10px 10px;
    padding-bottom: 0px;
    height: calc(100vh - 176px);    //176px is the top position(80px) + the total margin on the top and bottom (48px *2)
    max-height: $ai-desktop-sidebar-content-height;
    border: solid;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: -webkit-sticky;
    position: sticky;
    flex-shrink: 0;
    border-color: $ai-blue;
    z-index: 1;
    display:inline-block;
    @media (max-width: $desktop-width-xl) and (min-width: $desktop-width) {
        grid-row: 1 / span 2;
    }
    @media (max-width: $desktop-width) {
        display: none;
    }
}
#desktop-sidebar .sidebar-item{
    padding-bottom: 15px;
}
#mobile-sidebar{
    position: fixed;
    top: 70px;
    left: 16px;
    width: 50px;
    display: none;
    z-index: 1;
    @media (max-width: $desktop-width) {
        display: inline-block;
    }
}

#hamburger-content{
    flex-direction: column;
    position: fixed;
    top: 71px;          //71px is the height of the header bar
    left: 0px;
    z-index: 10;
    height: calc(100vh - 71px);
    width: 100vw;
    background: rgba(255, 255, 255, 1);
    overflow-y: auto;
    padding-top: 25px;
}
.hamburger-item{
    width: 350px;
    padding: 15px 10px;
    margin: 0px auto;
    flex-direction: column;
    font-size: 1.3em;
}

#sidebar-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90vw;

    button {
        z-index: 15;
        background: white;
        opacity: 90%;
        border-radius: 10px;
        padding: 3px 3px;
        margin-top: 10px;
        border: 2px solid $font-secondary-color;
        outline: 0;
        width: fit-content;
        font-family: inherit;
        font-size: inherit;

        &:hover{
            cursor: pointer;
        }
    }
}
.d-flex{
    display: flex;
}
.d-none{
    display: none;
}
