 // possibly move these to a minireset file
 * {
    box-sizing: border-box;
 }

 *:target {
    padding-top: 70px;
    margin-top: -70px;
}

 html{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-height:100vh;
 }
 body {
    margin: 0;
    padding: 0;
    padding-top: 70px;
    min-height: 100vh;
    min-width: 100%;
    font-family: $font-body;
    color: $font-primary-color;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    @media (max-width: $desktop-width) {
        padding-top: 50px;
    }
}

h1,h2,h3,h4,h5,h6 { 
    font-family: $font-header;
}

h1 {
    font-weight: 600; 
    font-size: 64px;
}

h2 {
    font-weight: 600; 
    font-size: 32px; 
}

h3 {
    font-weight: 500; 
    font-size: 18px; 
}

p {
    line-height: 1.5;
    font-weight: 400; 
}

label {
    font-size: 12px; 
    font-weight: 400; 
}

ul > li {
    line-height: 1.5;
}

.word-splitter {
    @media (min-width: $desktop-width) {
        display: none;
    }
}

.container {
    padding: 0 1em;
}

.container-fill{
    flex: 1 0 auto; // makes container grow into screen
}

.container-restricted {
    margin: auto;
    // width: 80vw;
    width: 1400px;
    // enforces a not-too-wide text viewport
    max-width: 100vw;
    @media (min-width: $desktop-width) {
        // adjusts restrction
        max-width: calc(100vw - 3em);
    }
}

.list-unstyled {
    padding: 0;
    margin: 0;
    list-style: none;
}

// likely move all text-* to text utilities
.text-teachla{
    color: $teachla-green;
}

.text-center{
    text-align: center;
}

.text-desktop-right {
    @media (min-width: $desktop-width) {
        text-align: right;
    }
}

.text-it{
    font-style: italic;
}

.text-normal{
    font-weight: normal;
}

// this should be refactored
.title{
    font-family: $font-display;
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 600;
}

.subtitle{
    font-family: $font-display;
    color: $teachla-green;
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 600;
}

.text-1x {
    font-size: 1rem !important;
}

.text-2x{
    font-size: 2em;
}

.text-12x{
    font-size: 1.2em;
}

.text-15x{
    font-size: 1.5em;
}

.text-25x{
    font-size: 2.5em;
}

.font-display{
    font-family: $font-display;
}

.tla-descrip {
    font-size: 5.5vw;

    @media (min-width: $desktop-width) {
        font-size: 1.5em;
    }
}

.page-title{
    font-size: 10vw;
    margin-bottom: 0;

    @media (min-width: $desktop-width) {
        font-size: 3em;
    }
}

.divider{
    border-top: 1px solid $teachla-green;
}

.justify-center {
    justify-content: center;
}

.auto-height {
    height: auto;
}

.flex-center {
    align-self: center;
    justify-self: center;
}

.img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.img-half-on-large{
    @media (min-width: $desktop-width) {
        max-width: 50%;
    }
}

.img-push-down{
    margin-top: 2em;
}

.img-curved{
    border-radius: 4px;
}

.img-push-up {
    margin-top: 16px;
    @media (min-width: $desktop-width) {
        margin-top: 32px;
    }
}

.img-decorated {
    border: 5px solid $teachla-green;
}

.no-margin{
    margin: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-50 {
    margin-bottom: 50px;
}

.my-3 {
    margin: 3em 0;
} 

.schedule-table-header {
    border-collapse: collapse;
    width: 100%;
    font-size: 24px;
    font-weight: 600;

    border-bottom: 2px solid transparent;
    background-image: linear-gradient(90deg,#5EDA12,#BCF223);
    background-origin: border-box;
    background-size: 100% 4px;
    background-position: bottom;
    background-repeat: no-repeat;
    table-layout: fixed;

    td {
        text-align: left;
        padding: 3vw;
        padding-top: 15px;
        padding-bottom: 15px;

        @media (min-width: $desktop-width) {
            padding: 60px 0 17px 65px;
        }
    }
}

// refactor this into a common table component
.schedule-table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin: 38px 0;
    table-layout: fixed;


    // removes last border line in table
    tr:last-child {
        border-bottom: 2px solid transparent;
    }

    tr {
        .middle-column {
            color: $teachla-green;
            font-weight: 600;
            // not sure if we want every link to be changed this way, but I'll change the link on this table specifically
            a {
                color: $teachla-green;
                text-decoration: none;
            }
        }

        td {
            border-bottom: 1px solid $teachla-green;
            text-align: left;
            
            padding: 3vw;
            padding-top: 15px;
            padding-bottom: 15px;

            @media (min-width: $desktop-width) {
                padding: 28px 0 28px 65px;
            }
        }

        // &:nth-child(even) {
        //     border-style: solid black;
        // }
    }
}

// this should be refactored
@mixin tla-link(
    $link-effect-color: $teachla-tint,
    $off-color: white,
    $effect-text-color: inherit
){

    color: inherit;
    font-weight: bold;
    text-decoration: none;
    background-position: left bottom;
    background-image: linear-gradient($off-color 0%, $link-effect-color 0%);
    background-size: 100% 15%;
    background-repeat: no-repeat;

    @media (min-width: $desktop-width) {
        transition: all 500ms ease;

        &:hover{
            background-size: 100% 85%;
            color: $effect-text-color;
        }
    }
}

.tla-link {
    @include tla-link();
}

.pronouns {
    font-size: $pronouns-font-size;
}



.img-sponsor {
    max-width: 80%;
}

.v-center-children {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.alert {
    border: 2px solid $teachla-green;
    border-radius: 4px;
    padding: 0.5em;
}

.resource-logo {
    max-height: 200px;
    max-width: 200px;
    width: auto;
    height: auto;
    margin: auto;
    @media (max-width: $desktop-width) {
       width: 90%;
   }
}

.resource-card {
    margin-top:1em;
    margin-bottom: 1em;
    height: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    @media (max-width: $desktop-width) {
        grid-template-columns: 100%;
    }
}

.resource-card-container {
    padding-left: 1em;
    padding-right: 1em;
    margin: 2em;
    border: 2px solid #5eda12;
    border-radius: 10px;
}

.landing-top{
    
    background-image: url("../img/landing-illustration.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh; /* Adjust this value based on the aspect ratio of the background image */
    margin: 0;
    padding: 20vh 0;
    // background-size: 100vw 100%;
}

.why-teach-la-column-left{
    float: left;
    width: 30%;
}
.why-teach-la-column-right{
    float: right;
    width: 70%;
}

.why-teach-la-row{
    content: "";
  display: table;
  clear: both;
}

.about-table{
    color: $teachla-green;
}